import { Plugin, Widget, toWidget,  ButtonView } from 'ckeditor5';

// Custom Horizontal Line Widget to adhere to content for Meetings in a Box
export default class HorizontalLineWidget extends Plugin {
    static get requires() {                                  
        return [ Widget ];
    }

    init() {
        const editor = this.editor;
        editor.ui.componentFactory.add('horizontalLineWidget', locale => {

            const button = new ButtonView(locale);

            button.set({
                label: 'Horizontal Line',
				tooltip: true,
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="1" viewBox="0 0 16 2"><rect width="16" height="1" fill="black"/></svg>',
                withText: false
            });
            button.on('execute', () => {
                editor.model.change(writer => {
                    const horizontalLineWidget = writer.createElement('horizontalLineWidget');        
                    // Insert the horizontalLineWidget into the editor at the current cursor position
                    editor.model.insertContent(horizontalLineWidget);
                });
            });
            
            return button;
        });
        
        editor.model.schema.register('horizontalLineWidget', {
            isObject: true,
            allowWhere: '$block'
        });
        
        this._defineConverters();
    }
    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast').elementToElement({
            model: 'horizontalLineWidget',
            view: {
                name: 'div',
                classes: 'horizontal-line-widget'
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'horizontalLineWidget',
            view: {
                name: 'div',
                classes: 'horizontal-line-widget'
            }
        });
        
        conversion.for('editingDowncast').elementToElement({
            model: 'horizontalLineWidget',
            view: (_modelElement, { writer }) => {
                const div = writer.createContainerElement('div', { class: 'horizontal-line-widget' });
                return toWidget(div, writer);
            }
        });
    }
}