import {
    Essentials, Bold, Italic, Paragraph, Heading, List, Image,
    FontSize, FontFamily, FontColor, Undo, MediaEmbed, Link, Underline, Strikethrough, ImageInsert, Indent,
    RemoveFormat, Subscript, Superscript, FindAndReplace, Base64UploadAdapter, Highlight, SelectAll, PageBreak, Clipboard, WordCount
} from 'ckeditor5';
import DownloadFileButton from './downloadFileButton';
import HorizontalLineWidget from './horizontalLineWidget';
export const editorConfig = (articleFiles) => {
    return {
        licenseKey: 'GPL',
        plugins: [
            Essentials, Paragraph, Heading, List, Bold, Italic, DownloadFileButton, Image,
            FontSize, FontFamily, FontColor, Undo, MediaEmbed, Link, Underline, Strikethrough, ImageInsert,
            Indent, RemoveFormat, Subscript, Superscript, FindAndReplace, Base64UploadAdapter, HorizontalLineWidget, Highlight, SelectAll, PageBreak, 
            Clipboard, WordCount
        ],
        downloadFileButtonConfig: {
            types: articleFiles?.map(file => file.name) || []
        },
        toolbar: {
            items: [
            'heading', 'downloadFileButton', 'bold', 'italic', 'numberedList', 'bulletedList',
            'strikethrough', 'underline', 'subscript', 'superscript', 'removeFormat',
            'indent', 'undo', 'redo', 'fontSize', 'fontFamily', 'fontColor',
            'link', 'mediaEmbed', 'findAndReplace', 'imageInsert', 'horizontalLineWidget', 'highlight', 'selectAll', 'pageBreak'
            ],
            shouldNotGroupWhenFull: true
        },          
        image: {
            toolbar: [
                'imageTextAlternative', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:side',
                '|', 'linkImage'
            ]
        },
        heading: {
            options: [
                { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                { model: "formatted", view: "pre", title: "Formatted", class: "ck-heading_formatted" },
            ]
        },
        fontFamily: {
            options: [
                "default",
                'ToyotaType-Regular',
                'ToyotaType-Light',
                'ToyotaType-Bold',
                'ToyotaType-Semibold',
                "Arial, sans-serif",
                "Comic Sans MS, cursive",
                "Courier New, Courier, monospace",
                "Georgia, serif",
                "Lucida, monospace",
                "Tahoma, sans-serif",
                "Times New Roman, serif",
                "Trebuchet MS, sans-serif",
                "Verdana, sans-serif",
            ],
            supportAllValues: true
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 25, 26, 28, 35, 36, 48, 72],
            supportAllValues: true
        },
        mediaEmbed: { previewsInData: true },
        link: {
            decorators: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: { download: 'file' }
                }
            }
        }
    }
}